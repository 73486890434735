import request from '@/utils/request'

/**
 * 抖音账号列表
 * @param data
 * @returns {*}
 */
export function http_session_account_list(data) {
    return request({
        url: '/session/account/list',
        method: 'post',
        data
    })
}


/**
 * 抖音账号详情
 * @param data
 * @returns {*}
 */
export function http_session_account_info(data) {
    return request({
        url: '/session/account/info',
        method: 'post',
        data
    })
}


/**
 * 私信用户列表
 * @param data
 * @returns {*}
 */
export function http_session_userinfo_list(data) {
    return request({
        url: '/session/userinfo/list',
        method: 'post',
        data
    })
}


/**
 * 私信用户详情
 * @param data
 * @returns {*}
 */
export function http_session_userinfo_info(data) {
    return request({
        url: '/session/userinfo/info',
        method: 'post',
        data
    })
}


/**
 * 更新用户详情
 * @param data
 * @returns {*}
 */
export function http_session_userinfo_update(data) {
    return request({
        url: '/session/userinfo/update',
        method: 'post',
        data
    })
}


/**
 * 清空用户未读消息提示
 * @param data
 * @returns {*}
 */
export function http_session_userinfo_clear_unread(data) {
    return request({
        url: '/session/userinfo/clear_unread',
        method: 'post',
        data
    })
}


/**
 * 切换用户自动回复状态
 * @param data
 * @returns {*}
 */
export function http_session_userinfo_toggle(data) {
    return request({
        url: '/session/userinfo/toggle',
        method: 'post',
        data
    })
}


/**
 * 聊天日志列表
 * @param data
 * @returns {*}
 */
export function http_session_chatlog_list(data) {
    return request({
        url: '/session/chatlog/list',
        method: 'post',
        data
    })
}


/**
 * 快捷回复分组列表
 * @param data
 * @returns {*}
 */
export function http_session_verbal_group_list(data) {
    return request({
        url: '/session/verbal/group_list',
        method: 'post',
        data
    })
}


/**
 * 快捷回复分组详情
 * @param data
 * @returns {*}
 */
export function http_session_verbal_group_info(data) {
    return request({
        url: '/session/verbal/group_info',
        method: 'post',
        data
    })
}


/**
 * 快捷回复分组添加
 * @param data
 * @returns {*}
 */
export function http_session_verbal_add_group_info(data) {
    return request({
        url: '/session/verbal/add_group_info',
        method: 'post',
        data
    })
}


/**
 * 快捷回复分组修改
 * @param data
 * @returns {*}
 */
export function http_session_verbal_update_group_info(data) {
    return request({
        url: '/session/verbal/update_group_info',
        method: 'post',
        data
    })
}


/**
 * 快捷回复分组删除
 * @param data
 * @returns {*}
 */
export function http_session_verbal_delete_group_info(data) {
    return request({
        url: '/session/verbal/delete_group_info',
        method: 'post',
        data
    })
}


/**
 * 快捷回复列表
 * @param data
 * @returns {*}
 */
export function http_session_verbal_content_list(data) {
    return request({
        url: '/session/verbal/content_list',
        method: 'post',
        data
    })
}


/**
 * 快捷回复详情
 * @param data
 * @returns {*}
 */
export function http_session_verbal_content_info(data) {
    return request({
        url: '/session/verbal/content_info',
        method: 'post',
        data
    })
}


/**
 * 快捷回复添加
 * @param data
 * @returns {*}
 */
export function http_session_verbal_add_content_info(data) {
    return request({
        url: '/session/verbal/add_content_info',
        method: 'post',
        data
    })
}


/**
 * 快捷回复修改
 * @param data
 * @returns {*}
 */
export function http_session_verbal_update_content_info(data) {
    return request({
        url: '/session/verbal/update_content_info',
        method: 'post',
        data
    })
}


/**
 * 快捷回复删除
 * @param data
 * @returns {*}
 */
export function http_session_verbal_delete_content_info(data) {
    return request({
        url: '/session/verbal/delete_content_info',
        method: 'post',
        data
    })
}



/**
 * 发送文本消息
 * @param data
 * @returns {*}
 */
export function http_session_send_text(data) {
    return request({
        url: '/session/send/text',
        method: 'post',
        data
    })
}





/**
 * 发送图片消息
 * @param data
 * @returns {*}
 */
export function http_session_send_image(data) {
    return request({
        url: '/session/send/image',
        method: 'post',
        data
    })
}




