import request from '@/utils/request'



/**
 * 统计线形图
 * @param data
 * @returns {*}
 */
export function http_statistics_chat_data(data) {
    return request({
        url: '/statistics/chat/data',
        method: 'post',
        data
    })
}


/**
 * 统计表格数据
 * @param data
 * @returns {*}
 */
export function http_statistics_table_data(data) {
    return request({
        url: '/statistics/table/data',
        method: 'post',
        data
    })
}

