//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {http_session_account_list} from "@/api/session.js"
import {http_statistics_chat_data, http_statistics_table_data} from "@/api/statistics.js";
import ECharts from "vue-echarts/components/ECharts";
import "echarts/lib/chart/line";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";


export default {
  name: "Index",
  components: {
    "v-chart": ECharts
  },
  data() {
    return {
      account_list: [],
      account_info: {},
      count: 0,
      active_index: -1,
      account_page_size:300,

      LineDataList: [],
      dayActionIndex: 0,
      dayWhereList: [{name: '7天', id: 7}, {name: '15天', id: 15}, {name: '30天', id: 30}],
      lineData: {
        title: {text: '折线图堆叠'},
        tooltip: {trigger: 'axis'},
        legend: {data: []},
        grid: {left: '3%', right: '4%', bottom: '3%', containLabel: true},
        toolbox: {feature: {saveAsImage: {}}},
        xAxis: {type: 'category', boundaryGap: false, data: []},
        yAxis: {type: 'value'},
        series: []
      },
      tableData: [],
      tableCount: 0,
      page: 1,
      page_size: 10
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.request_http_session_account_list();
    })
  },
  methods: {
    /**
     * 加载授权账号列表
     */
    request_http_session_account_list() {
      http_session_account_list({page: this.page, page_size: this.account_page_size}).then(res => {
        if (res.code == 0) {
          let data = res.data.data;
          this.account_list = data;
          this.count = res.data.count;
          this.$store.commit('set_statistics_merchant_list', res.data.data);
          if (this.active_index == -1 && data.length > 0) {
            this.on_select_account(data[0], 0);
            this.active_index = 0;
          }
        }
      })
    },
    /**
     * 选择授权账号
     * @param row
     * @param index
     */
    on_select_account(row, index) {
      this.active_index = index;
      this.account_info = row;
      this.$store.commit('set_statistics_merchant_item', row);
      this.request_http_statistics_chat_data(row);
      this.request_http_statistics_table_data(row);
    },
    /**
     * 选择图表线条统计的天数
     * @param item
     * @param index
     */
    on_select_day(item, index) {

      this.dayActionIndex = index;
      this.request_http_statistics_chat_data(this.account_info);
    },
    /**
     * 加载图标线条统计
     * @param request_data
     */
    request_http_statistics_chat_data(request_data) {
      let request_arg = {
        account_id: request_data.open_id,
        day_id: this.dayWhereList[this.dayActionIndex].id
      }
      http_statistics_chat_data(request_arg).then(res => {
        if (res.code == 0) {
          this.lineData.legend.data = res.data.legend;
          this.lineData.xAxis.data = res.data.xAxis;
          this.lineData.series = res.data.series;
        }
      })
    },
    request_http_statistics_table_data(request_data) {
      let request_arg = {page: this.page, page_size: this.page_size, account_id: request_data.open_id};
      http_statistics_table_data(request_arg).then(res => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.tableCount = res.data.count;
        }
      })
    },
    ChangePage(page) {
      this.page = page;
      this.request_http_statistics_table_data(this.account_info);
    },
    SizeChange(page_size){
      this.page = 1;
      this.page_size = page_size;
      this.request_http_statistics_table_data(this.account_info);
    }
  }
}
